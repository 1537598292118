/* eslint-disable no-param-reassign */
// import { gql } from 'graphql-request'
import { useEffect, useState } from 'react'
import { PoolData } from 'state/info/types'
// import { infoClient } from 'utils/graphql'
import { getChangeForPeriod } from 'utils/getChangeForPeriod'
import { getLpFeesAndApr } from 'utils/getLpFeesAndApr'
import { getDeltaTimestamps } from 'utils/getDeltaTimestamps'
import { useBlocksFromTimestamps } from 'views/Info/hooks/useBlocksFromTimestamps'
import { getPercentChange } from 'views/Info/utils/infoDataHelpers'
import { GET_PAIR_DETAIL } from 'config/constants/endpoints'
import { sortby } from 'utils'

interface PoolFields {
  id: string
  reserve0: string
  reserve1: string
  reserveUSD: string
  volumeUSD: string
  token0Price: string
  token1Price: string
  token0: {
    id: string
    symbol: string
    name: string
  }
  token1: {
    id: string
    symbol: string
    name: string
  }
}

export interface FormattedPoolFields
  extends Omit<PoolFields, 'volumeUSD' | 'reserveUSD' | 'reserve0' | 'reserve1' | 'token0Price' | 'token1Price'> {
  volumeUSD: number
  reserveUSD: number
  reserve0: number
  reserve1: number
  token0Price: number
  token1Price: number
}

// interface PoolsQueryResponse {
//   now: PoolFields[]
//   oneDayAgo: PoolFields[]
//   twoDaysAgo: PoolFields[]
//   oneWeekAgo: PoolFields[]
//   twoWeeksAgo: PoolFields[]
// }

interface PoolDatas {
  error: boolean
  data?: {
    [address: string]: PoolData
  }
  day?: []
  month?: []
  week?: []
}

export const fetchPoolData = async (poolAddresses: string[]) => {
  try {
    const res = await fetch(`${GET_PAIR_DETAIL}?address=${poolAddresses[0]}`)
    const resData = await res.json()
    // console.log('获取交易对详情', resData)
    const result = resData?.data
    let poolData: PoolData
    if (resData?.status === 200) {
      const current = result.pair
      poolData = {
        address: current.address,
        token0: {
          address: current.token0Asset?.address,
          name: current.token0Asset?.name,
          symbol: current.token0Asset?.symbol,
          icon: current.token0Asset?.icon,
        },
        token1: {
          address: current.token1Asset?.address,
          name: current.token1Asset?.name,
          symbol: current.token1Asset?.symbol,
          icon: current.token1Asset?.icon,
        },
        token0Price: 0,
        token1Price: 0,
        volumeUSD: 0,
        volumeUsd24h: current.stats.volumeUsd24h,
        volumeUsd7d: current.stats.volumeUsd7d,
        volumeUsd30d: current.stats.volumeUsd30d,
        volumeUSDChange: 0,
        volumeUSDWeek: 0,
        volumeUSDChangeWeek: 0,
        txCount24h: current.stats.txCount24h,
        txCount7d: current.stats.txCount7d,
        txCount30d: current.stats.txCount30d,
        reserve0: current.stats.reserve0,
        reserve1: current.stats.reserve1,
        totalFees24h: current.stats.totalFees24h,
        totalFees7d: current.stats.totalFees7d,
        totalFees30d: current.stats.totalFees30d,
        totalFees90d: current.stats.totalFees90d,
        lpFees7d: 0,
        lpFees24h: 0,
        lpApr7d: 0,
        pairName: current.pair_name,
        lpRewardApr: current.stats.lpRewardApr,
        lpRewardFees24h: current.stats.lpRewardFees24h,
        liquidityUSD: current.stats.liquidity,
        liquidityUSDChange: 0,
        liquidityToken0: current.stats.liquidity0,
        liquidityToken1: current.stats.liquidity1,
      }
    }

    const data = {
      poolData,
      day: result.day && result.day.length > 0 ? sortby(result.day, 'dateUnix') : [],
      month: result.month && result.month.length > 0 ? sortby(result.month, 'dateUnix') : [],
      week: result.week && result.week.length > 0 ? sortby(result.week, 'weekUnix') : [],
    }
    return { data, error: false }
  } catch (error) {
    console.error('Failed to fetch pool data', error)
    return { error: true }
  }
}

export const useFetchedPoolData = (poolAddresses: string[]): PoolDatas => {
  const [fetchState, setFetchState] = useState<PoolDatas>({ error: false })
  // const [t24h, t48h, t7d, t14d] = getDeltaTimestamps()
  // const { blocks, error: blockError } = useBlocksFromTimestamps([t24h, t48h, t7d, t14d])
  // const [block24h, block48h, block7d, block14d] = blocks ?? []
  useEffect(() => {
    const fetch = async () => {
      const { error, data } = await fetchPoolData(poolAddresses)
      if (error) {
        setFetchState({ error: true })
      } else {
        // Calculate data and format
        const formatted = poolAddresses.reduce((accum: { [address: string]: PoolData }, address) => {
          accum[address] = data.poolData
          return accum
        }, {})
        setFetchState({ data: formatted, day: data.day, month: data.month, week: data.week, error: false })
      }
    }

    if (poolAddresses.length) {
      fetch()
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [poolAddresses.length])

  return fetchState
}

// Transforms pools into "0xADDRESS: { ...PoolFields }" format and cast strings to numbers
export const parsePoolData = (pairs?: PoolFields[]) => {
  if (!pairs) {
    return {}
  }
  return pairs.reduce((accum: { [address: string]: FormattedPoolFields }, poolData) => {
    const { volumeUSD, reserveUSD, reserve0, reserve1, token0Price, token1Price } = poolData
    accum[poolData.id] = {
      ...poolData,
      volumeUSD: parseFloat(volumeUSD),
      reserveUSD: parseFloat(reserveUSD),
      reserve0: parseFloat(reserve0),
      reserve1: parseFloat(reserve1),
      token0Price: parseFloat(token0Price),
      token1Price: parseFloat(token1Price),
    }
    return accum
  }, {})
}

/**
 * Fetch top pools by liquidity
 */
const usePoolDatas = (poolAddresses: string[]): PoolDatas => {
  const [fetchState, setFetchState] = useState<PoolDatas>({ error: false })
  const [t24h, t48h, t7d, t14d] = getDeltaTimestamps()
  const { blocks, error: blockError } = useBlocksFromTimestamps([t24h, t48h, t7d, t14d])
  const [block24h, block48h, block7d, block14d] = blocks ?? []

  useEffect(() => {
    const fetch = async () => {
      const { error, data } = await fetchPoolData(
        // block24h.number,
        // block48h.number,
        // block7d.number,
        // block14d.number,
        poolAddresses,
      )
      if (error) {
        setFetchState({ error: true })
      } else {
        const formattedPoolData = parsePoolData([])
        const formattedPoolData24h = parsePoolData([])
        const formattedPoolData48h = parsePoolData([])
        const formattedPoolData7d = parsePoolData([])
        const formattedPoolData14d = parsePoolData([])

        // Calculate data and format
        const formatted = poolAddresses.reduce((accum: { [address: string]: PoolData }, address) => {
          // Undefined data is possible if pool is brand new and didn't exist one day ago or week ago.
          const current: FormattedPoolFields | undefined = formattedPoolData[address]
          const oneDay: FormattedPoolFields | undefined = formattedPoolData24h[address]
          const twoDays: FormattedPoolFields | undefined = formattedPoolData48h[address]
          const week: FormattedPoolFields | undefined = formattedPoolData7d[address]
          const twoWeeks: FormattedPoolFields | undefined = formattedPoolData14d[address]

          const [volumeUSD, volumeUSDChange] = getChangeForPeriod(
            current?.volumeUSD,
            oneDay?.volumeUSD,
            twoDays?.volumeUSD,
          )
          const [volumeUSDWeek, volumeUSDChangeWeek] = getChangeForPeriod(
            current?.volumeUSD,
            week?.volumeUSD,
            twoWeeks?.volumeUSD,
          )

          const liquidityUSD = current ? current.reserveUSD : 0

          const liquidityUSDChange = getPercentChange(current?.reserveUSD, oneDay?.reserveUSD)

          const liquidityToken0 = current ? current.reserve0 : 0
          const liquidityToken1 = current ? current.reserve1 : 0

          const { totalFees24h, totalFees7d, lpFees24h, lpFees7d, lpApr7d } = getLpFeesAndApr(
            volumeUSD,
            volumeUSDWeek,
            liquidityUSD,
          )

          if (current) {
            accum[address] = {
              address,
              token0: {
                address: current.token0.id,
                name: current.token0.name,
                symbol: current.token0.symbol,
              },
              token1: {
                address: current.token1.id,
                name: current.token1.name,
                symbol: current.token1.symbol,
              },
              token0Price: current.token0Price,
              token1Price: current.token1Price,
              volumeUSD,
              volumeUSDChange,
              volumeUSDWeek,
              volumeUSDChangeWeek,
              volumeUsd24h: 0,
              volumeUsd7d: 0,
              volumeUsd30d: 0,
              txCount24h: 0,
              txCount7d: 0,
              txCount30d: 0,
              totalFees24h,
              totalFees7d,
              totalFees30d: 0,
              totalFees90d: 0,
              lpFees24h,
              lpFees7d,
              lpApr7d,
              liquidityUSD,
              liquidityUSDChange,
              liquidityToken0,
              liquidityToken1,
              reserve0: 0,
              reserve1: 0,
            }
          }

          return accum
        }, {})
        setFetchState({ data: formatted, error: false })
      }
    }

    const allBlocksAvailable = block24h?.number && block48h?.number && block7d?.number && block14d?.number
    if (poolAddresses.length > 0 && allBlocksAvailable && !blockError) {
      fetch()
    }
  }, [poolAddresses, block24h, block48h, block7d, block14d, blockError])

  return fetchState
}

export default usePoolDatas
