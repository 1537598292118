// import { GraphQLClient } from 'graphql-request'
// import { getGQLHeaders } from 'utils/graphql'
// import requestWithTimeout from 'utils/requestWithTimeout'

/**
 * Helper function to get large amount GraphQL subqueries
 * @param queryConstructor constructor function that combines subqueries
 * @param subqueries individual queries
 * @param endpoint GraphQL endpoint
 * @param skipCount how many subqueries to fire at a time
 * @returns
 */
export const multiQuery = async (
  queryConstructor: (subqueries: string[]) => string,
  subqueries: string[],
  endpoint: string,
  skipCount = 1000,
) => {
  const fetchedData = {}
  // const allFound = false
  // const skip = 0
  // const client = new GraphQLClient(endpoint, { headers: getGQLHeaders(endpoint) })
  try {
    // while (!allFound) {
    //   let end = subqueries.length
    //   if (skip + skipCount < subqueries.length) {
    //     end = skip + skipCount
    //   }
    //   const subqueriesSlice = subqueries.slice(skip, end)
    //   // eslint-disable-next-line no-await-in-loop
    //   const result: any = await requestWithTimeout(client, queryConstructor(subqueriesSlice))
    //   fetchedData = {
    //     ...fetchedData,
    //     ...result,
    //   }
    //   allFound = Object.keys(result).length < skipCount || skip + skipCount > subqueries.length
    //   skip += skipCount
    // }
    return fetchedData
  } catch (error) {
    console.error('Failed to fetch info data', error)
    return null
  }
}
